<script lang="ts">
import {defineComponent} from 'vue'
import {MagnifyingGlassIcon, UserIcon, FaceFrownIcon, GlobeAmericasIcon} from "@heroicons/vue/24/outline";
import {useMagicKeys} from '@vueuse/core'
import type {SearchResponse} from '~/types/responses';
import {startCase} from "lodash-es";
import {useNotificationStore} from "~/stores/util/notifications";

const keys = useMagicKeys()

export default defineComponent({
  name: "Search",
  components: {UserIcon, MagnifyingGlassIcon, FaceFrownIcon, GlobeAmericasIcon},
  data() {
    return {
      isSearchModelOpen: false,
      query: '',
      results: {},
      filteredItems: [],
      groups: [],
      CtrlShiftF: keys['Ctrl+Shift+F']
    }
  },
  watch: {
    query: debounce(function (val) {
      if (val === '') {
        this.results = {}
        return
      }
      $api('/shop/search', {
        method: 'get',
        params: {
          search: val
        }
      }).then((response: SearchResponse) => {
        this.results = response;
      })


    }, 500),
    CtrlShiftF: {
      handler: function (val) {
        if (val) {
          this.isSearchModelOpen = true
        }
      },
      immediate: true
    }
  },
  methods: {
    startCase,
    handleNavigation(item) {
      const type = item.split('-')[0]
      const id = item.split('-')[1]
      const object = this.results[type].find((item) => item.id == id)
      let url = ''

      switch (type) {
        case 'categories':
          url = '/categories/' + id + '-' + object.slug
          break;
        case 'products':
          url = '/products/' + id + '/' + object.slug
          break;
        case 'tags':
          useNotificationStore().addNotification({
            message: "Searching by Tags coming soon!",
            type: 'error',
            duration: 5000,
          })
          break;
        case 'stores':
          url = '/vendors/' + id + '/' + object.slug
          break;
      }
      this.isSearchModelOpen = false
      this.query = ''
      this.results = {}
      if (url !== '') {
        navigateTo(url)
      }
    }

  },
  computed:
      {
        filteredResults() {
          return Object.keys(this.results).filter((key) => {
            return this.results[key].length > 0
          })
        }
      }
})


</script>

<template>
  <div class="flex lg:ml-6">
    <NuxtLink href="/products"
            class="p-2 text-gray-400 dark:text-gray-500 hover:text-gray-500 dark:hover:text-gray-600">
      <span class="sr-only">Open search</span>
      <MagnifyingGlassIcon class="h-6 w-6" aria-hidden="true"/>
    </NuxtLink>
    <HeadlessTransitionRoot :show="isSearchModelOpen" as="template" @after-leave="query = ''" appear>
      <HeadlessDialog as="div" class="relative z-[60]" @close="isSearchModelOpen = false">
        <HeadlessTransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0"
                                 enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100"
                                 leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity"/>
        </HeadlessTransitionChild>

        <div class="fixed inset-0 z-[60] w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
          <HeadlessTransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 scale-95"
                                   enter-to="opacity-100 scale-100" leave="ease-in duration-200"
                                   leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
            <HeadlessDialogPanel
                class="mx-auto max-w-xl transform overflow-hidden rounded-xl bg-white dark:bg-zinc-900 shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <HeadlessCombobox @update:modelValue="handleNavigation">
                <div class="relative">
                  <MagnifyingGlassIcon
                      class="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400 dark:text-gray-500"
                      aria-hidden="true"/>
                  <HeadlessComboboxInput
                      class="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 dark:text-white placeholder:text-gray-400 dark:placeholder:text-gray-500 focus:ring-0 sm:text-sm"
                      placeholder="Search..." @change="query = $event.target.value"/>
                </div>

                <div v-if="query === ''"
                     class="border-t border-gray-900/10 dark:border-zinc-700 px-6 py-14 text-center text-sm sm:px-14">
                  <GlobeAmericasIcon class="mx-auto h-6 w-6 text-gray-400" aria-hidden="true"/>
                  <p class="mt-4 font-semibold text-gray-900 dark:text-white">Search the site</p>
                  <p class="mt-2 text-gray-500">Quickly access all content across the site. Using this search you can
                    easily get access to stores, tags, categories and products.</p>
                </div>

                <HeadlessComboboxOptions v-if="filteredResults.length > 0" static
                                         class="max-h-80 scroll-pb-2 scroll-pt-11 space-y-2 overflow-y-auto pb-2">
                  <li v-for="type in filteredResults" :key="type">
                    <h2 class="bg-gray-100 px-4 py-2.5 text-xs font-semibold text-gray-900 dark:bg-zinc-800 dark:text-gray-200">
                      {{ startCase(type) }}
                    </h2>
                    <ul class="mt-2 text-sm text-gray-800 dark:text-gray-400">
                      <HeadlessComboboxOption v-for="item in results[type]" :key="item.id" :value="type + '-' + item.id"
                                              as="template"
                                              v-slot="{ active }">
                        <li :class="['cursor-default select-none px-4 py-2', active && 'bg-primary-600 text-white']">
                          {{ item.name }}
                        </li>
                      </HeadlessComboboxOption>
                    </ul>
                  </li>
                </HeadlessComboboxOptions>

                <div v-if="query !== '' && Object.keys(results).length === 0"
                     class="border-t border-gray-900/10 dark:border-zinc-700 px-6 py-14 text-center text-sm sm:px-14">
                  <FaceFrownIcon class="mx-auto h-6 w-6 text-gray-400" aria-hidden="true"/>
                  <p class="mt-4 font-semibold text-gray-900">No results found</p>
                  <p class="mt-2 text-gray-500">We couldn’t find anything with that term. Please try again.</p>
                </div>
              </HeadlessCombobox>
            </HeadlessDialogPanel>
          </HeadlessTransitionChild>
        </div>
      </HeadlessDialog>
    </HeadlessTransitionRoot>

  </div>
</template>

<style scoped>

</style>