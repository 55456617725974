<script lang="ts">
import {defineComponent} from 'vue'
import {UserIcon} from "@heroicons/vue/24/outline";
import { useUserStore } from '~/stores/user';
import {mapState} from "pinia";
export default defineComponent({
  name: "AccountButton",
  components: {UserIcon},
  methods: {
    async logout() {
      useUserStore().logout()
      navigateTo("/login")
    }
  },
  computed: {
    ...mapState(useUserStore, ['getUser']),
    ...mapState(useUserStore, ['isAuthenticated']),
  },
})
</script>

<template>
  <HeadlessMenu as="div" class="relative inline-block text-left">
    <div>
      <HeadlessMenuButton class="p-2 text-gray-400 dark:text-gray-500 group-hover:text-gray-500 dark:group-hover:text-gray-600">
        <span class="sr-only">Account</span>
        <UserIcon class="h-6 w-6" aria-hidden="true" />
      </HeadlessMenuButton>
    </div>

    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
      <HeadlessMenuItems class="absolute right-0 z-10 mt-2 p-1 w-56 origin-top-right rounded-md bg-white dark:bg-zinc-900 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-100 dark:divide-zinc-800">
        <div class="py-1" v-if="!isAuthenticated">
          <HeadlessMenuItem v-slot="{ active }">
            <NuxtLink href="/login" :class="[active ? 'bg-gray-100 dark:bg-zinc-800 dark:text-gray-100 text-gray-900' : 'text-gray-700 dark:text-gray-400', 'block px-4 py-2 text-sm']">Login</NuxtLink>
          </HeadlessMenuItem>
          <HeadlessMenuItem v-slot="{ active }">
            <NuxtLink href="/register" :class="[active ? 'bg-gray-100 text-gray-900  dark:bg-zinc-800 dark:text-gray-100' : 'text-gray-700 dark:text-gray-400', 'block px-4 py-2 text-sm']">Register</NuxtLink>
          </HeadlessMenuItem>
        </div>
        <div v-if="isAuthenticated" class="px-4 py-3">
          <p class="text-sm dark:text-gray-400">Signed in as</p>
          <p v-if="getUser" class="truncate text-sm font-medium text-gray-900 dark:text-gray-300">{{ getUser.email }}</p>
        </div>
        <div v-if="isAuthenticated" class="py-1">
          <HeadlessMenuItem v-slot="{ active }">
            <NuxtLink href="/profile" :class="[active ? 'bg-gray-100 text-gray-900 dark:bg-zinc-800 dark:text-gray-100' : 'text-gray-700  dark:text-gray-400', 'block px-4 py-2 text-sm']">Account settings</NuxtLink>
          </HeadlessMenuItem>
          <HeadlessMenuItem v-slot="{ active }">
            <NuxtLink href="/profile/orders" :class="[active ? 'bg-gray-100 text-gray-900 dark:bg-zinc-800 dark:text-gray-100' : 'text-gray-700  dark:text-gray-400', 'block px-4 py-2 text-sm']">Past orders</NuxtLink>
          </HeadlessMenuItem>
        </div>
        <div v-if="isAuthenticated" class="py-1">
          <HeadlessMenuItem v-slot="{ active }">
            <button @click="logout" type="submit" :class="[active ? 'bg-gray-100 text-gray-900 dark:bg-zinc-800 dark:text-gray-100' : 'text-gray-700  dark:text-gray-400', 'block w-full px-4 py-2 text-left text-sm']">Sign out</button>
          </HeadlessMenuItem>
        </div>
        <HeaderColorModeButtons/>
      </HeadlessMenuItems>
    </transition>
  </HeadlessMenu>
</template>

<style scoped>

</style>