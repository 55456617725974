export function debounce(fn: { (): void; apply?: any; }, wait: number | undefined){
    let timer: string | number | NodeJS.Timeout | undefined;
    return function(...args: any){
        if(timer) {
            clearTimeout(timer); // clear any pre-existing timer
        }
        const context = this; // get the current context
        timer = setTimeout(()=>{
            fn.apply(context, args); // call the function if time expires
        }, wait);
    }
}