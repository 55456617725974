<script lang="ts">
import {defineComponent} from 'vue'
import {XMarkIcon} from "@heroicons/vue/24/outline";

export default defineComponent({
  name: "MobileMenu",
  components: {XMarkIcon},
  emits: ['close'],
  props: {
    open: {
      type: Boolean,
      default: false
    },
    pages: {
      type: Array,
      default: () => []
    },
    featured: {
      type: Array,
      default: () => []
    },
    categories: {
      type: Array,
      default: () => []
    },
  },
})
</script>

<template>
  <HeadlessTransitionRoot as="template" :show="open">
    <HeadlessDialog as="div" class="relative z-40 lg:hidden" @close="$emit('close')">
      <HeadlessTransitionChild as="template" enter="transition-opacity ease-linear duration-300"
                               enter-from="opacity-0" enter-to="opacity-100"
                               leave="transition-opacity ease-linear duration-300" leave-from="opacity-100"
                               leave-to="opacity-0">
        <div class="fixed inset-0 bg-black bg-opacity-25"/>
      </HeadlessTransitionChild>

      <div class="fixed inset-0 z-40 flex">
        <HeadlessTransitionChild as="template" enter="transition ease-in-out duration-300 transform"
                                 enter-from="-translate-x-full" enter-to="translate-x-0"
                                 leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0"
                                 leave-to="-translate-x-full">
          <HeadlessDialogPanel
              class="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white dark:bg-zinc-900 pb-12 shadow-xl">
            <div class="flex justify-between px-4 pb-2 pt-5">
              <button type="button"
                      class="relative -m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400 dark:text-gray-500"
                      @click="$emit('close')">
                <span class="absolute -inset-0.5"/>
                <span class="sr-only">Close menu</span>
                <XMarkIcon class="h-6 w-6" aria-hidden="true"/>
              </button>
              <HeaderCartButton/>
              <HeaderAccountButton/>
            </div>

            <!-- Links -->
            <HeadlessTabGroup as="div" class="mt-2">
              <div class="border-b border-gray-900/10 dark:border-zinc-700">
                <HeadlessTabList class="-mb-px flex space-x-8 px-4">
                  <HeadlessTab as="template"
                               v-slot="{ selected }">
                    <button
                        :class="[selected ? 'border-primary-600 text-primary-600' : 'border-transparent text-gray-900', 'flex-1 whitespace-nowrap border-b-2 px-1 py-4 text-base font-medium']">
                      Resources
                    </button>
                  </HeadlessTab>
                </HeadlessTabList>
              </div>
              <HeadlessTabPanels as="template">
                <HeadlessTabPanel
                                  class="space-y-10 px-4 pb-8 pt-10">
                  <div class="grid grid-cols-2 gap-x-4">
                    <div v-for="item in featured" :key="item.name" class="group relative text-sm">
                      <div
                          class="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-100 group-hover:opacity-75">
                        <NuxtImg format="webp" v-if="item.thumbnail?.full_url" :src="item.thumbnail?.full_url" :alt="item.short_description" class="object-cover object-center"/>
                      </div>
                      <a :href="'/products/' + item.id + '/' + item.slug" class="mt-6 block font-medium text-gray-900 dark:text-white">
                        <span class="absolute inset-0 z-10" aria-hidden="true"/>
                        {{ item.name }}
                      </a>
                      <p aria-hidden="true" class="mt-1 text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-white">Shop Now</p>
                    </div>
                  </div>
                  <div v-for="category in categories" :key="category.slug">
                    <p :id="`${category.slug}-heading-mobile`" class="font-medium text-gray-900 dark:text-white">
                      {{ category.name }}</p>
                    <ul role="list" :aria-labelledby="`${category.slug}-heading-mobile`"
                        class="mt-6 flex flex-col space-y-6">
                      <li v-for="child in category.children" :key="child.slug" class="flow-root">
                        <a :href="'/categories/' + category.id + '-' + category.slug" class="-m-2 block p-2 text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-white">{{ child.name }}</a>
                      </li>
                    </ul>
                  </div>
                </HeadlessTabPanel>
              </HeadlessTabPanels>
            </HeadlessTabGroup>

            <div class="space-y-6 border-t border-gray-900/10 dark:border-zinc-700 px-4 py-6">
              <div v-for="page in pages" :key="page.name" class="flow-root">
                <a :href="page.href" class="-m-2 block p-2 font-medium text-gray-900 dark:text-white">{{ page.name }}</a>
              </div>
            </div>
          </HeadlessDialogPanel>
        </HeadlessTransitionChild>
      </div>
    </HeadlessDialog>
  </HeadlessTransitionRoot>

</template>

<style scoped>

</style>