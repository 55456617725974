<script lang="ts">
import {defineComponent} from 'vue'
import CookieConsent from "~/components/CookieConsent.vue";
export default defineComponent({
  name: "default",
  components: {CookieConsent}
})
</script>

<template>
  <NuxtLoadingIndicator color="#FF0000" :height="4"/>
  <div class="content flex flex-col justify-center min-h-screen bg-gray-100 dark:bg-zinc-900 pt-24 z-1 ">
    <div class="content__main flex flex-col flex-1">
      <LayoutHeader/>
      <slot />
    </div>
    <LayoutFooter/>
    <Notifications/>
    <CookieConsent />
  </div>
</template>

<style scoped>

</style>