<script lang="ts">
import {defineComponent} from 'vue'

export default defineComponent({
  name: "Footer",
  computed: {
    year() {
      return new Date().getFullYear()
    },
    navigation() {
      return {
        'Links': [
          {name: 'Home', 'href': '/'},
          {name: 'About', 'href': '/information/about'},
          {name: 'Contact', 'href': 'https://discord.gg/mcmodels'},
        ],
        'Tutorials': [
          {name: 'FAQ', 'href': '/guides'},
          {name: 'Terms', 'href': '/information/terms'},
          {name: 'Privacy', 'href': '/information/privacy'},
        ],
        'Misc': [
          {name: 'Youtube', 'href': 'https://www.youtube.com/@minecraftmodels9555'},
          {name: 'Tiktok', 'href': 'https://www.youtube.com/@minecraftmodels9555'},
          {name: 'X (Twitter)', 'href': 'https://twitter.com/MCModelsNet'},
        ]
      }
    }
  }
})
</script>

<template>
  <div class="px-4 sm:px-6 lg:px-8 pb-8 pt-8">
    <div class="border-t border-gray-900/10 dark:border-zinc-700">
      <div class="grid grid-cols-2 sm:grid-cols-4 mt-5">
        <div class="col-span-2 sm:col-span-1 h-full flex items-center justify-center">
          <div>
            <div class="flex justify-center">
              <NuxtImg format="webp" src="/img/logo_full_white_red.png" alt="The MCModels logo" class="w-3/4 lg:w-2/4 hidden dark:block"/>
              <NuxtImg format="webp" src="/img/logo_full_black_red.png" alt="The MCModels logo" class="w-3/4 lg:w-2/4 dark:hidden"/>
            </div>
            <div class="flex justify-center gap-6 mt-4">
              <NuxtLink to="https://www.youtube.com/channel/UCqBZTZtK5rocGY7OHCOdEMg">
                <svg xmlns="http://www.w3.org/2000/svg" class="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-white h-5" fill="currentColor" viewBox="0 0 576 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"/></svg>
              </NuxtLink>
              <NuxtLink to="https://discord.gg/MCModels">
                <svg xmlns="http://www.w3.org/2000/svg" class="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-white h-5" viewBox="0 0 640 512"  fill="currentColor">
                  <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                  <path
                      d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z"/>
                </svg>
              </NuxtLink>
              <NuxtLink to="https://twitter.com/MCModelsNet">
                <svg xmlns="http://www.w3.org/2000/svg" class="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-white h-5" fill="currentColor" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg>
              </NuxtLink>

            </div>

          </div>
        </div>
        <div v-for="key in Object.keys(navigation)" :key="key" class="mt-4 sm:mt-0">
          <h3 class="text-sm font-semibold leading-6 text-gray-900 dark:text-white text-center sm:text-left">{{
              key
            }}</h3>
          <ul class="space-y-2 mt-2 text-center sm:text-left">
            <li v-for="link in navigation[key]" :key="link">
              <a :href="link.href"
                 class="text-sm leading-6 text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-white">{{ link.name }}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="flex justify-center mt-8">
        <div>
          <p class="text-center text-xs leading-5 text-gray-500">Copyright MCModels ©2023. </p>
        </div>
      </div>
    </div>

  </div>
</template>

<style scoped>

</style>