<script setup lang="ts">

import Header from "~/components/layout/Header.vue";
import {Bars3Icon, ChevronDownIcon} from "@heroicons/vue/24/outline";
import type {CategoriesResponse, ProductsResponse} from "~/types/responses";
import * as Sentry from "@sentry/vue";
import type {Category, Product} from "~/types/models";

//Load categories
let categories = ref<Category[]>([])
const {data: categoriesData, error: categoriesError, status}: { data: Ref<CategoriesResponse>, error: any, status: any } = await useFetch(useRuntimeConfig().public.newBaseURL + '/shop/categories/', {
  query: {
    parent_id: null,
    disable_pagination: false
  }
});
if (!categoriesError.value) {
  categories = categoriesData.value.data
} else {
  console.log(categoriesError.value)
}

//Load featured products
let featuredProducts = ref<Product[]>([])
const {data: productsData, error: productsError}: { data: Ref<ProductsResponse>, error: any } = await useFetch(useRuntimeConfig().public.newBaseURL + '/shop/products/', {
  query: {
    disable_pagination: true,
    sort_by: 'random',
    limit: 2,
    'store_ids[]': [4],
    // is_exclusive: true,
    non_exclusive_build: false,
  }
});
if (!productsError.value) {
  featuredProducts = productsData.value.data
} else {
  console.log(productsError.value)
}

const open = ref(false)
const pages = [
  {name: 'Shop Now', href: '/products', vendor: false},
  {name: 'Guides', href: '/guides', vendor: false},
  {name: 'Vendor Guides', href: '/guides/vendor', vendor: true},
  {name: 'News', href: '/news', vendor: false},
  {name: 'Vendors', href: '/vendors', vendor: false},
  {name: 'Product Support', href: 'https://discord.gg/mcmodels', vendor: false},
]

const filteredPages = computed(() => {
  const userType = useUserStore().user?.type; // Assuming this gives you the user type (e.g., 'vendor', 'admin')
  return pages.filter(page => !page.vendor || (page.vendor && ['vendor', 'admin'].includes(userType)));
});
</script>

<template>
  <client-only>
    <div class="bg-white w-full justify-around fixed right-0 top-0 left-0 z-50 ">
      <!-- Mobile menu -->
      <HeaderMobileMenu :open="open" :pages="pages" :categories="categories" :featured="featuredProducts" @close="open = false"/>

      <header class="relative bg-gray-100 dark:bg-zinc-900 z-200">
        <nav aria-label="Top" class="mx-0 px-4 sm:px-6 lg:px-8">
          <div class="border-b border-gray-900/10 dark:border-zinc-700">
            <div class="flex h-20 items-center">
              <button type="button" class="relative rounded-md bg-gray-100 dark:bg-zinc-900 p-2 text-gray-400 dark:text-gray-500 lg:hidden" @click="open = true">
                <span class="absolute -inset-0.5"/>
                <span class="sr-only">Open menu</span>
                <Bars3Icon class="h-6 w-6" aria-hidden="true"/>
              </button>

              <!-- Logo -->
              <div class="ml-4 flex lg:ml-0">
                <NuxtLink href="/">
                  <span class="sr-only">MCModels</span>
                  <NuxtImg format="webp" height="48" width="226" class="lg:h-12 h-8 w-auto dark:hidden" src="/img/logo_full_black_red.png" alt="The MCModels logo"/>
                  <NuxtImg format="webp" height="48" width="226" class="lg:h-12 h-8 w-auto hidden dark:block" src="/img/logo_full_white_red.png" alt="The MCModels logo"/>
                </NuxtLink>
              </div>

              <!-- Flyout menu -->
              <HeadlessPopoverGroup class="hidden lg:ml-8 lg:block lg:self-stretch">
                <div class="flex h-full space-x-8">
                  <HeadlessPopover class="flex" v-slot="{ open }">
                    <div class="relative flex">
                      <HeadlessPopoverButton
                          :class="[open ? 'border-primary-600 text-primary-600' : 'border-transparent text-gray-700 hover:text-gray-800 dark:text-gray-300 dark:hover:text-white',
                          'relative z-20 -mb-px flex items-center border-b-2 pt-px text-md font-bold transition-colors duration-200 ease-out',
                           'focus:outline-none']">
                        Resources
                        <ChevronDownIcon class="h-4 w-4 text-gray-700 dark:text-gray-300 dark:hover:text-white ml-1" aria-hidden="true" />
                      </HeadlessPopoverButton>
                    </div>

                    <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0"
                                enter-to-class="opacity-100" leave-active-class="transition ease-in duration-150"
                                leave-from-class="opacity-100" leave-to-class="opacity-0">
                      <HeadlessPopoverPanel class="absolute inset-x-0 top-full text-sm text-gray-500">
                        <!-- Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow -->
                        <div class="absolute inset-0 top-1/2 bg-gray-100 dark:bg-zinc-900 shadow" aria-hidden="true"/>

                        <div class="relative bg-gray-100 dark:bg-zinc-900">
                          <div class="mx-auto max-w-7xl px-8">
                            <div class="grid grid-cols-2 gap-x-8 gap-y-10 py-16">
                              <div class="col-start-2 grid grid-cols-2 gap-x-8">


                                <div v-for="item in featuredProducts" :key="item.name"
                                     class="group relative text-base sm:text-sm">
                                  <div
                                      class="aspect-square overflow-hidden rounded-lg bg-gray-100 group-hover:opacity-75">
                                    <NuxtImg format="webp" v-if="item.thumbnail && item.thumbnail.full_url" :src="item.thumbnail?.full_url" class="object-cover object-center"/>
                                  </div>
                                  <a :href="'/products/' + item.id + '/' + item.slug" class="mt-6 block font-medium text-gray-900 dark:text-white">
                                    <span class="absolute inset-0 z-10" aria-hidden="true"/>
                                    {{ item.name }}
                                  </a>
                                  <p aria-hidden="true" class="mt-1 text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-white">Shop Now</p>
                                </div>
                              </div>


                              <div class="row-start-1 grid grid-cols-4 gap-x-4 gap-y-5 text-sm">
                                <div v-for="category in categories" :key="category.slug" class="border-b pb-2 border-gray-900">
                                  <a :href="'/categories/' + category.id + '-' + category.slug"  :id="`${category.slug}-heading`" class="font-bold text-lg text-gray-900 dark:text-white ">{{
                                      category.name
                                    }}
                                  </a>
                                  <ul role="list" :aria-labelledby="`${category.slug}-heading`"
                                      class="mt-4 space-y-4 sm:mt-3 sm:space-y-3">
                                    <li v-for="child in category.children" :key="child.slug" class="flex">
                                      <a :href="'/categories/' + child.id + '-' + child.slug" class="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-white">{{ child.name }}</a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </HeadlessPopoverPanel>
                    </transition>
                  </HeadlessPopover>

                  <a v-for="page in filteredPages" :key="page.name" :href="page.href"
                     class="flex items-center text-md font-bold text-gray-700 hover:text-red-500 dark:text-gray-300 dark:hover:text-red-600">{{ page.name }}</a>
                </div>
              </HeadlessPopoverGroup>

              <div class="ml-auto flex items-center gap-4">

                <!-- Search -->
                <HeaderSearch class="hidden md:block"/>

                <!-- Cart -->
                <HeaderCartButton/>

                <!-- Account -->
                <HeaderAccountButton/>

                <div v-if="['vendor', 'admin'].includes(useUserStore().user?.type)">
                  <Button @click="navigateTo(useRuntimeConfig().public.dashboardURL, { external: true })">Vendor dashboard</Button>
                </div>

              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  </client-only>

</template>
