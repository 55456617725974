<script lang="ts">
import {defineComponent} from 'vue'
import {ShoppingBagIcon} from "@heroicons/vue/24/outline";
import { useCartStore } from '~/stores/cart';
import {useUserStore} from "~/stores/user";
import {mapState} from "pinia";

export default defineComponent({
  name: "CartButton",
  components: {ShoppingBagIcon},
  computed: {
    ...mapState(useCartStore, ['count']),
    ...mapState(useUserStore, ['getUser']),
    ...mapState(useUserStore, ['isAuthenticated']),

  },
  mounted() {
    if (this.isAuthenticated) {
      useCartStore().fetchCart()
    }
  },
  watch: {
    getUser() {
      if (!this.isAuthenticated) return
      useCartStore().fetchCart()
    }
  }
})
</script>

<template>
  <div class="flow-root">
    <NuxtLink href="/cart" class="group -m-2 flex items-center p-2">
      <ShoppingBagIcon class="h-6 w-6 flex-shrink-0 text-gray-400 dark:text-gray-500 group-hover:text-gray-500 dark:group-hover:text-gray-600"
                       aria-hidden="true"/>
      <span class="ml-2 text-sm font-medium text-gray-700 dark:text-gray-300 group-hover:text-gray-800 dark:group-hover:text-gray-400">
        {{ count }}
      </span>
      <span class="sr-only">items in cart, view bag</span>
    </NuxtLink>
  </div>
</template>

<style scoped>

</style>