<script setup lang="ts">
import { onMounted } from 'vue';
import { useUserStore } from "~/stores/user";

// Function to update Google Analytics consent
function updateGoogleAnalyticsConsent() {
  const { gtag } = useGtag();
  gtag('consent', 'update', {
    ad_user_data: 'granted',
    ad_personalization: 'granted',
    ad_storage: 'granted',
    analytics_storage: 'granted'
  });
}
function consent() {
  const userStore = useUserStore();
  userStore.setCookies(true);
  updateGoogleAnalyticsConsent();
}

const cookies = computed(() => useUserStore().getCookies);

onMounted(() => {
  if (cookies.value) {
    updateGoogleAnalyticsConsent();
  }
});
</script>

<template>
  <div v-if="!cookies" class="fixed bottom-4 left-1/2 bg-white border-1 border-black rounded-lg shadow-lg max-w-[600px] w-[90%] z-50 p-4 -translate-x-1/2 flex flex-row gap-2">
    <div>
      <p class="text-sm">We use cookies and similar technologies to ensure you get the best experience on our website.</p>
    </div>
    <button class="uppercase w-1/3 bg-red-500 rounded-md font-bold text-white" @click="consent">Got It!</button>
  </div>
</template>

<style scoped>

</style>