<template>
    <div aria-live="assertive" class="z-[1005] fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start">
        <div class="z-[1005] w-full flex flex-col items-center space-y-4 sm:items-end">
            <NotificationCard v-for="notification in notifications" :title="notification.message" message="" :type="notification.type"/>
        </div>
    </div>
</template>

<script>
import {useNotificationStore} from "~/stores/util/notifications";
export default {
    name: "Notifications",
    computed: {
        notifications() {
            return useNotificationStore().notifications
        }
    }

}
</script>
